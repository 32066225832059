<template v-slot:item.action="props">
  <v-menu
    bottom
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        size="20px"
        v-bind="attrs"
        class="ml-auto"
        v-on="on"
      >
        {{ icons.mdiDotsVertical }}
      </v-icon>
    </template>
    <v-list>
      <v-list-item>
        <router-link :to="{ name: 'user-detail', params: { id: data.id } }">
          Lihat Detail
        </router-link>
      </v-list-item>
      <!-- <v-list-item
        @click="$refs.userForm.update(props.item)"
      >
        Ubah Data
      </v-list-item> -->
      <v-list-item
        class="error--text"
        @click="removeUser(data)"
      >
        Hapus Pengguna
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { mdiDotsVertical } from '@mdi/js'

export default {
  setup() {
    const data = reactive({})

    return {
      data,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
