import { ref } from '@vue/composition-api'
import { apolloClient } from '@/vue-apollo'
import { customAttributeList, customAttributes, customAttributeTypes } from '@/graphql/queries'
import {
  createCustomAttribute as addCustomAttribute, updateCustomAttribute, deleteCustomAttribute, changeCustomAttributeValue,
} from '@/graphql/mutations'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import Vue from 'vue'
import dateFormat from '@/utils/dateFormat'

const useCustomAttributes = ({
  objectTypeId,
} = {}) => {
  const loadingFetch = ref(false)
  const loadingTypes = ref(false)
  const loadingAddTypes = ref(false)
  const loadingEditTypes = ref(false)

  const customAttributesArray = ref([])
  const attributeTypeList = ref([])

  const fetchCustomAttributes = async (objectId, jobTypeId) => {
    loadingFetch.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: customAttributes,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            object_type_id: objectTypeId,
            object_id: objectId,
            job_type_id: jobTypeId,
          },
        })
        .then(({ data }) => {
          const ret = data.customAttributes.map(el => {
            let val
            if (el.custom_attribute.data_type.id === 4) val = el.value ? +el.value : null
            else if (el.custom_attribute.data_type.id === 5) val = JSON.parse(el.value ? el.value : null)
            else val = el.value

            return {
              ...el, value: val,
            }
          })
          customAttributesArray.value = ret
          resolve(ret)
          loadingFetch.value = false
        })
        .catch(error => {
          errorHandling(error)
          reject(error)
          loadingFetch.value = false
        })
    })
  }

  const fetchAttributeTypes = async () => {
    loadingTypes.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: customAttributeTypes,
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          attributeTypeList.value = data.customAttributeDataTypes
          resolve(data.customAttributeTypes)
          loadingTypes.value = false
        })
        .catch(error => {
          errorHandling(error)
          reject(error)
          loadingTypes.value = false
        })
    })
  }

  const createCustomAttribute = async payload => {
    loadingAddTypes.value = true
    console.log((payload.type === 4 || payload.type === 5) ? payload.options : null)

    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: addCustomAttribute,
          fetchPolicy: 'no-cache',
          variables: {
            name: payload.name,
            data_type_id: payload.type,
            object_type_id: objectTypeId,
            workspace_id: store.getters.getCurrentWorkspaceId,
            options: payload.options,
            job_type_id: payload.job_type_id,
          },
        })
        .then(({ data }) => {
          loadingAddTypes.value = false
          resolve(data.createCustomAttribute)

          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menambahkan atribut baru!',
          })
        })
        .catch(error => {
          loadingAddTypes.value = false
          errorHandling(error)
          reject(error)
        })
    })
  }

  const editCustomAttribute = async payload => {
    loadingAddTypes.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: updateCustomAttribute,
        fetchPolicy: 'no-cache',
        variables: {
          id: payload.id,
          name: payload.name,
          options: payload.type === 4 || payload.type === 5 ? payload.options : null,
          delete_options: payload.delete_options,
        },
      }).then(({ data }) => {
        loadingAddTypes.value = false
        resolve(data.updateCustomAttribute)
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah atribut!',
        })
      }).catch(error => {
        loadingAddTypes.value = false
        errorHandling(error)
        reject(error)
      })
    })
  }

  const removeCustomAttribute = async id => {
    loadingAddTypes.value = true
    console.log(id)

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: deleteCustomAttribute,
        fetchPolicy: 'no-cache',
        variables: {
          id,
        },
      }).then(({ data }) => {
        loadingAddTypes.value = false
        resolve(data.deleteCustomAttribute)
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil menghapus atribut!',
        })
      }).catch(error => {
        loadingAddTypes.value = false
        errorHandling(error)
        reject(error)
      })
    })
  }

  const editCustomAttributeValue = async payload => {
    loadingEditTypes.value = true

    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: changeCustomAttributeValue,
        fetchPolicy: 'no-cache',
        variables: {
          objectId: payload.objectId,
          typeId: payload.typeId,
          values: payload.values,
        },
      }).then(({ data }) => {
        loadingEditTypes.value = false
        resolve(data.changeCustomAttributeValue)
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah nilai atribut!',
        })
      }).catch(error => {
        loadingEditTypes.value = false
        errorHandling(error)
        reject(error)
      })
    })
  }

  const fetchCustomAttributeList = async () => {
    loadingFetch.value = true

    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: customAttributeList,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            object_type_id: objectTypeId,
          },
        })
        .then(({ data }) => {
          resolve(data.customAttributeList)
          console.log(data)
          customAttributesArray.value = data.customAttributeList
          loadingFetch.value = false
        })
        .catch(error => {
          errorHandling(error)
          reject(error)
          loadingFetch.value = false
        })
    })
  }

  const getAttributeValue = attr => {
    if (!attr.value) return '-'
    if (attr.custom_attribute.data_type.id === 3) return dateFormat(attr.value, 3)
    if (attr.custom_attribute.data_type.id === 4) return attr.custom_attribute.options.find(option => option.id === attr.value)?.name
    if (attr.custom_attribute.data_type.id === 5) return attr.custom_attribute.options.filter(option => attr.value.includes(option.id)).map(option => option.name).join(', ')

    return attr.value
  }

  return {
    loadingFetch,
    loadingTypes,
    loadingAddTypes,
    loadingEditTypes,

    customAttributesArray,
    attributeTypeList,

    fetchCustomAttributes,
    fetchAttributeTypes,
    fetchCustomAttributeList,

    createCustomAttribute,
    editCustomAttribute,
    removeCustomAttribute,
    editCustomAttributeValue,
    getAttributeValue,
  }
}

export default useCustomAttributes
