import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"size":"20px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])]}}])},[_c(VList,[_c(VListItem,[_c('router-link',{attrs:{"to":{ name: 'user-detail', params: { id: _vm.data.id } }}},[_vm._v(" Lihat Detail ")])],1),_c(VListItem,{staticClass:"error--text",on:{"click":function($event){return _vm.removeUser(_vm.data)}}},[_vm._v(" Hapus Pengguna ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }