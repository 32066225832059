<template>
  <v-autocomplete
    ref="customerTypeInput"
    v-model="inputData"
    placeholder="Tag Pelanggan"
    :label="label"
    outlined
    dense
    :loading="loading"
    :items="types"
    item-text="name"
    item-value="id"
    multiple
    chips
    deletable-chips
    small-chips
    hide-details="auto"
    @update:search-input="onSearch"
    @update:list-index="onIndex"
    @keydown="onKeyDown"
  >
    <template #no-data>
      <div
        v-if="searchQuery && !readOnly"
        class="d-flex justify-space-between px-4 py-2 cursor-pointer"
        @click="addTag"
      >
        <span class="text-subtitle-2">Tambahkan <v-chip small>{{ searchQuery }}</v-chip> dan pilih.</span>
        <v-icon size="20">
          {{ icons.mdiPlus }}
        </v-icon>
      </div>
      <div
        v-else
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        Tag tidak ditemukan. {{ readOnly ? '' : 'Ketik sesuatu dan buat tag baru.' }}
      </div>
    </template>
    <template
      v-if="types.length"
      #append-item
    >
      <div
        v-if="searchQuery && !isEmpty && !readOnly && !$can('create', 'CustomerType')"
        class="d-flex justify-space-between px-4 py-2 cursor-pointer"
        @click="addTag"
      >
        <span class="text-subtitle-2">Tambahkan <v-chip small>{{ searchQuery }}</v-chip> dan pilih.</span>
        <v-icon size="20">
          {{ icons.mdiPlus }}
        </v-icon>
      </div>
    </template>
    <template #item="{ item }">
      <v-hover #default="{ hover: isHovered }">
        <div class="d-flex w-full justify-space-between py-2">
          <v-chip
            small
            :color="item.color"
          >
            {{ item.name }}
          </v-chip>
          <v-menu
            v-if="!readOnly"
            v-model="vMenuModel[item.id]"
            :close-on-content-click="false"
            max-width="290px"
            min-width="290px"
            offset-y
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-slide-x-reverse-transition mode="out-in">
                <v-icon
                  v-show="isHovered"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiDotsHorizontal }}
                </v-icon>
              </v-slide-x-reverse-transition>
            </template>

            <v-card
              class="pa-3"
            >
              <v-text-field
                v-model="item.name"
                dense
                :disabled="!$can('update', 'CustomerType')"
                placeholder="Update Tags"
                hide-details
                outlined
                :append-icon="item.name && $can('update', 'CustomerType') ? icons.mdiCheck : null"
                @keydown="$event.keyCode === 13 ? updateTag(item) : null"
                @blur="fetchTypes"
                @click:append="updateTag(item)"
              />
              <color-picker
                v-if=" $can('update', 'CustomerType')"
                v-model="item.color"
                class="mt-6 mb-4"
                @input="$event ? updateTag(item, $event) : null"
              />
              <v-btn
                v-if="$can('del', 'CustomerType')"
                color="error"
                block
                small
                outlined
                class="mt-2"
                @click="deleteTag(item)"
              >
                Hapus Tag
              </v-btn>
            </v-card>
          </v-menu>
        </div>
      </v-hover>
    </template>
    <template #selection="{ item }">
      <v-chip
        small
        :color="item.color"
        class="mt-2"
      >
        {{ item.name }}
      </v-chip>
    </template>
    <template #append-outer>
      <slot name="match" />
    </template>
  </v-autocomplete>
</template>

<script>
import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'
import {
  mdiPlus, mdiDotsHorizontal, mdiCheck, mdiClose,
} from '@mdi/js'
import { apolloClient } from '@/vue-apollo'
import { customerTypes } from '@/graphql/queries'
import { addCustomerTypes, deleteCustomerTypes, updateCustomerTypes } from '@/graphql/mutations'
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import { randomizeColor } from '@/utils/colorSelection'
import { required } from '@core/utils/validation'
import store from '@/store'

export default {
  components: {
    ColorPicker,
  },
  props: {
    value: {
      type: Array,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: 'Tag Pelanggan',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const types = ref([])
    const loading = ref(true)
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })
    const customerTypeInput = ref(null)
    const vMenuModel = ref({})

    const fetchTypes = () => {
      apolloClient.query({
        query: customerTypes,
        fetchPolicy: 'no-cache',
        variables: {
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        loading.value = false
        types.value = result.data.customerTypes
        result.data.customerTypes.forEach(el => {
          vMenuModel.value[el.id] = false
        })
      }).catch(() => {
        loading.value = false
      })
    }
    fetchTypes()

    const searchQuery = ref('')
    const onSearch = data => {
      searchQuery.value = data
    }

    const addTag = () => {
      const filterTag = types.value.find(el => el.name === searchQuery.value)

      if (filterTag) {
        if (!inputData.value.some(el => el === filterTag.id)) {
          emit('input', [
            ...inputData.value,
            filterTag.id,
          ])
        }
        customerTypeInput.value.lazySearch = ''
      } else {
        loading.value = true
        apolloClient.mutate({
          mutation: addCustomerTypes,
          variables: {
            name: searchQuery.value,
            color: randomizeColor().hex,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(result => {
          loading.value = false
          searchQuery.value = ''
          emit('input', [
            ...inputData.value,
            JSON.parse(result.data.addCustomerTypes.data).id,
          ])
          fetchTypes()
          customerTypeInput.value.lazySearch = ''
        }).catch(() => {
          loading.value = false
        })
      }
    }

    const onKeyDown = data => {
      if (data.keyCode === 13 && searchQuery.value) {
        addTag()
      }
    }

    const isEmpty = ref(false)
    const onIndex = () => {
      isEmpty.value = customerTypeInput.value.filteredItems.length === 0
    }

    const updateTag = item => {
      apolloClient.mutate({
        mutation: updateCustomerTypes,
        variables: {
          id: item.id,
          name: item.name,
          color: item.color,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(() => {
        vMenuModel.value[item.id] = false
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengubah tag!',
        })
        fetchTypes()
      })
    }

    const deleteTag = item => {
      Vue.$dialog({
        title: 'Hapus tag ini?',
        body: 'Anda yakin ingin menghapus tag ini?',
      }).then(confirm => {
        if (confirm) {
          apolloClient.mutate({
            mutation: deleteCustomerTypes,
            variables: {
              id: item.id,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil menghapus tag!',
            })
            fetchTypes()
            customerTypeInput.value.focus()
            customerTypeInput.value.activateMenu()
          })
        }
      })
    }

    return {
      inputData,
      types,
      vMenuModel,
      loading,
      customerTypeInput,
      searchQuery,
      required,
      onSearch,
      addTag,
      onKeyDown,
      deleteTag,
      updateTag,
      fetchTypes,
      isEmpty,
      onIndex,

      icons: {
        mdiPlus,
        mdiDotsHorizontal,
        mdiCheck,
        mdiClose,
      },
    }
  },
}
</script>

<style>
.v-list-item {
  width: 100% !important;
}
</style>
