<template>
  <v-dialog
    v-model="isOpen"
    max-width="800"
    scrollable
    @input="!$event ? reset() : null"
  >
    <v-card>
      <div class="d-flex justify-space-between pa-4">
        <span class="text-h6 font-medium primary--text">{{ updateMode ? 'Ubah Pengguna' : 'Tambah Pengguna' }}</span>
        <v-icon
          @click="close()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-2">
        <v-form
          ref="userForm"
          lazy-validation
          @submit.prevent="submitForm"
        >
          <v-card
            outlined
            class="pa-4 d-flex flex-column align-center justify-center mb-4"
            @click="$refs.avatarPicker.click()"
          >
            <v-avatar
              size="120"
              class="v-avatar-light-bg"
            >
              <v-img :src="avatar" />
            </v-avatar>
            <span
              class="mt-2 text-subtitle-2 text--disabled"
            >
              Foto Profil
            </span>
          </v-card>
          <input
            ref="avatarPicker"
            type="file"
            class="d-none"
            accept="image/*"
            @change="onFileChange($event.target.files)"
          >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.first_name"
                :rules="[required]"
                label="Nama Awal (wajib diisi)"
                placeholder="Input nama awal pengguna"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.last_name"
                :rules="[required]"
                label="Nama Akhir (wajib diisi)"
                placeholder="Input nama awal pengguna"
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.phone"
                :rules="[required]"
                label="Nomor Telepon (wajib diisi)"
                placeholder="Input nomor Telepon pengguna"
                outlined
                dense
                type="number"
                :loading="loadingPhoneChecker"
                :success="!errorPhoneChecker && errorPhoneChecker !== null"
                :error="errorPhoneChecker"
                :error-messages="errorPhoneChecker ? 'Nomor Telepon sudah dipakai' : null"
                hide-details="auto"
                @input="checkPhone"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.email"
                label="Email"
                placeholder="Input email pengguna"
                outlined
                dense
                type="email"
                :rules="[emailValidator]"
                hide-details="auto"
              />
            </v-col>
            <v-col
              v-if="!updateMode"
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="formData.password"
                label="Password (wajib diisi)"
                placeholder="Input password pengguna"
                outlined
                dense
                autocomplete="new-password"
                :rules="[required, lengthValidator(formData.password, 8)]"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              />
              <v-row>
                <v-col class="pt-0">
                  <password
                    v-model="formData.password"
                    :strength-meter-only="true"
                    @score="showScore"
                  />
                </v-col>
                <v-col
                  v-if="passwordScore.variant"
                  class="d-flex pt-0"
                  cols="auto"
                >
                  <small :class="`ml-auto ${passwordScore.variant}`">{{ passwordScore.message }}</small>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="formData.role"
                :items="roleList"
                label="Role (wajib diisi)"
                outlined
                item-text="name"
                item-value="id"
                return-object
                dense
                :rules="[required]"
              />
            </v-col>
          </v-row>
          <div class="d-flex flex-column flex-md-row mt-4">
            <v-btn
              class="mb-2 mb-md-0 mr-md-2 ml-md-auto"
              outlined
              @click="reset()"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="primary"
              :loading="loadingSubmit"
              @click="submitForm()"
            >
              Simpan Pengguna
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay
      opacity="0.2"
      :value="loadingDetail"
    >
      <v-progress-circular
        indeterminate
        size="32"
      />
    </v-overlay>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'
import { mdiClose, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import Password from 'vue-password-strength-meter'
import { required, emailValidator, lengthValidator } from '@core/utils/validation'
import store from '@/store'
import router from '@/router'
import { apolloClient } from '@/vue-apollo'
import { phoneChecker, addUser } from '@/graphql/mutations'
import phoneFormatter from '@/utils/phoneFormatter'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    Password,
  },
  setup(props, { emit }) {
    const userForm = ref()
    const isOpen = ref(false)
    const corpId = computed(() => store.state.auth.userData.corporation.id)
    const isPasswordVisible = ref(false)
    const formData = ref({
      phone: '',
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      role: null,
      corp_id: corpId,
      photo: null,
    })
    const loadingSubmit = ref(false)
    const loadingDetail = ref(false)
    const updateMode = ref(false)
    const initialData = ref({})

    const roleList = computed(() => store.state.user.roles)
    if (!roleList.length) {
      store.dispatch('user/getRoles')
    }

    const avatar = ref('')
    const onFileChange = files => {
      avatar.value = URL.createObjectURL(files[0])

      // eslint-disable-next-line prefer-destructuring
      formData.value.photo = files[0]
    }

    const reset = () => {
      updateMode.value = false
      loadingSubmit.value = false
      loadingDetail.value = false

      // reset
      formData.value = {
        phone: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        role: null,
        corp_id: corpId,
        photo: null,
      }

      userForm.value.reset()
    }
    const show = () => {
      isOpen.value = true
    }
    const close = () => {
      isOpen.value = false
      reset()
    }

    const update = data => {
      // console.log(data, 'data')
      isOpen.value = true
      initialData.value = data
      loadingDetail.value = true
      formData.value = {
        phone: data.phone,
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        password: null,
        role: data.role,
        corp_id: corpId,
        photo: data.photo,
      }
      if (data.photo) avatar.value = data.photo
      updateMode.value = true
      loadingDetail.value = false
    }

    const submitForm = () => {
      if (userForm.value.validate()) {
        loadingSubmit.value = true
        apolloClient.mutate({
          mutation: addUser,
          variables: {
            ...formData.value,
            role: formData.value.role.id,
          },
        }).then(result => {
          loadingSubmit.value = false
          emit('success')
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil menambahkan pengguna!',
          })
          const data = JSON.parse(result.data.addUser.data)
          router.push({ name: 'user-detail', params: { id: data.id } })
        }).catch(err => {
          errorHandling(err)
          loadingSubmit.value = false
        })
      }
    }

    // phone checker
    const loadingPhoneChecker = ref(false)
    const errorPhoneChecker = ref(null)
    const checkPhone = useDebounceFn(async () => {
      if (formData.value.phone && formData.value.phone.length > 8) {
        loadingPhoneChecker.value = true
        await apolloClient.mutate({
          mutation: phoneChecker,
          variables: {
            phone: phoneFormatter(formData.value.phone),
          },
        }).then(result => {
          loadingPhoneChecker.value = false
          if (result.data.phoneChecker.status === 'error') {
            errorPhoneChecker.value = true
          } else {
            errorPhoneChecker.value = false
          }
        }).catch(() => {
          errorPhoneChecker.value = true
          loadingPhoneChecker.value = false
        })
      }
    }, 1000)

    const passwordScore = ref({
      variant: '',
      message: '',
    })
    const showScore = score => {
      if (score >= 0 && score < 2) {
        passwordScore.value = {
          variant: 'text-danger',
          message: 'Password Lemah',
        }
      } else if (score === 3) {
        passwordScore.value = {
          variant: 'text-info',
          message: 'Password Cukup Bagus',
        }
      } else if (score > 3) {
        passwordScore.value = {
          variant: 'text-success',
          message: 'Password Sangat Bagus',
        }
      }

      if (score === null) {
        passwordScore.value = {
          variant: '',
          message: '',
        }
      }
    }

    return {
      userForm,
      isOpen,
      formData,
      loadingSubmit,
      loadingDetail,
      updateMode,
      isPasswordVisible,
      roleList,
      avatar,
      onFileChange,

      show,
      close,
      reset,
      update,
      submitForm,

      loadingPhoneChecker,
      errorPhoneChecker,
      checkPhone,

      passwordScore,
      showScore,

      icons: {
        mdiClose,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },

      required,
      emailValidator,
      lengthValidator,
    }
  },
}
</script>

<style>

</style>
