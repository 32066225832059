<template>
  <div class="d-flex align-center">
    <v-menu
      open-on-hover
      right
      offset-x
      nudge-right="20"
      close-delay="100"
      max-height="350"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="36"
          class="v-avatar-light-bg mr-2"
          v-bind="attrs"
          v-on="on"
          @mouseover="fetchPopoverUser(data.id)"
        >
          {{ avatarText(data.name) }}
        </v-avatar>
      </template>
      <v-card>
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title class="text-h5">
              {{ userName }}
            </v-card-title>

            <v-card-subtitle>{{ userEmail }}</v-card-subtitle>
            <v-col class="w-full pl-4 pt-2">
              <span class="">Hierarki</span>
              <v-row v-if="hierarchies">
                <v-col>
                  <v-card
                    outlined
                    class="pa-3 mt-2 d-flex justify-space-between"
                    @click.stop
                  >
                    <router-link :to="{ name: 'hierarchy'}">
                      <span class="text-subtitle-2 primary--text">{{ hierarchies.name }}</span>
                    </router-link>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else />
            </v-col>
            <v-col class="w-full pl-4 mt-1">
              <span class="">Team(s)</span>
              <v-card
                v-for="i in teams"
                :key="i-1"
                outlined
                class="pa-3 mt-2 w-full d-flex justify-space-between align-center overflow-auto"
                @click.stop
              >
                <router-link :to="{ name: 'team-detail', params: { id: i.id } }">
                  <span class="text-subtitle-2 primary--text">{{ i.name }}</span>
                </router-link>
                <v-btn
                  v-if="i.status === 'not joined'"
                  class="ms-2"
                  variant="outlined"
                  @click="handleJoin(i.id, i)"
                >
                  Join
                </v-btn>
                <v-btn
                  v-else-if="i.status === 'not requested'"
                  class="ms-2"
                  variant="outlined"
                  @click="handleRequestJoin(i.id, i)"
                >
                  Request
                </v-btn>
                <v-btn
                  v-else-if="i.status === 'requested'"
                  class="ms-2"
                  variant="outlined"
                  @click="handleRequestJoin(i.id, i)"
                >
                  Re-request
                </v-btn>
                <div v-else />
              </v-card>
            </v-col>
          </div>

          <v-avatar
            class="v-avatar-light-bg ma-3"
            size="80"
          >
            {{ avatarText(`${firstName} ${lastName}`) }}
          </v-avatar>
        </div>
      </v-card>
    </v-menu>
    <div>
      <router-link :to="{ name: 'user-detail', params: { id: data.id } }">
        <span class="primary--text font-medium d-block">{{ data.name }}</span>
      </router-link>
      <span>{{ data.email }}</span>
    </div>
  </div>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import { reactive, ref } from '@vue/composition-api'
import { apolloClient } from '@/vue-apollo'
import { popoverUsers } from '@/graphql/queries'
import store from '@/store'
import useTeam from '../../team/useTeam'
import errorHandling from '@/utils/errorHandling'

export default {
  setup() {
    const data = reactive({})
    const popoverUser = ref([])
    const userEmail = ref([])
    const userName = ref([])
    const firstName = ref([])
    const lastName = ref([])
    const hierarchies = ref([])
    const teams = ref([])
    const { jTeam, requestJoin } = useTeam()

    const fetchPopoverUser = id => {
      apolloClient.query({
        query: popoverUsers,
        variables: {
          user_id: id,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        popoverUser.value = result.data.popoverUsers
        userEmail.value = popoverUser.value.user.email
        userName.value = popoverUser.value.user.name
        firstName.value = popoverUser.value.user.first_name
        lastName.value = popoverUser.value.user.last_name
        hierarchies.value = popoverUser.value.hierarchy
        teams.value = popoverUser.value.teams
        console.log('pop', popoverUser.value)
        console.log('pop2', hierarchies.value)
      })
    }

    const setStatus = team => {
      if (team.status === 'not joined') {
        // eslint-disable-next-line no-param-reassign
        team.status = 'joined'
      } else if (team.status === 'not requested') {
        // eslint-disable-next-line no-param-reassign
        team.status = 'requested'
      } else if (team.status === 'requested') {
        // eslint-disable-next-line no-param-reassign
        team.status = 'requested'
      }
    }

    const handleJoin = (id, team) => {
      jTeam(id).then(() => {
        setStatus(team)
      }).catch(err => errorHandling(err))
    }

    const handleRequestJoin = (id, team) => {
      requestJoin(id).then(() => {
        setStatus(team)
      }).catch(err => errorHandling(err))
    }

    return {
      avatarText,
      data,
      popoverUser,
      userEmail,
      userName,
      firstName,
      lastName,
      hierarchies,
      teams,
      fetchPopoverUser,
      handleJoin,
      handleRequestJoin,
      setStatus,
    }
  },
}
</script>
