<template>
  <div>
    <v-card>
      <v-row
        class="mx-2 pt-2"
      >
        <v-col>
          <h3>Daftar Pengguna</h3>
        </v-col>
        <v-col
          cols="auto"
          class="ms-auto d-flex"
        >
          <input-icon-toggle
            v-model="search"
            class="ms-2"
            :icon="icons.mdiMagnify"
            placeholder="Cari pengguna"
            @input="searchUser"
            @close="state.userFilter.value.search = ''; fetchWorkspaceMember()"
          />
          <v-menu
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8px"
            nudge-left="20px"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="menu">
              <v-tooltip bottom>
                <template v-slot:activator="tooltip">
                  <v-icon
                    size="26"
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    {{ icons.mdiChevronDown }}
                  </v-icon>
                </template>
                <span>Cari pengguna berdasarkan...</span>
              </v-tooltip>
            </template>
            <v-card>
              <!-- <v-card-text>
                Cari Berdasarkan
                <div
                  v-for="(field, index) in state.search_field.value"
                  :key="index"
                >
                  <v-switch
                    v-model="field.value"
                    inset
                    flat
                    dense
                    hide-details
                    :label="field.label"
                    @change="fetchWorkspaceMember()"
                  />
                </div>
              </v-card-text> -->
            </v-card>
          </v-menu>
          <!-- <input-icon-toggle
            v-model="search"
            class="ms-2 me-3"
            :icon="icons.mdiMagnify"
            placeholder="Cari pengguna"
            :modal="$vuetify.breakpoint.smAndDown"
            @input="searchUser"
            @close="search = ''; dataStateChange({ skip: 0, take: pagination.itemsPerPage })"
          /> -->
          <!-- <v-menu
            v-model="isArchiveOpen"
            :close-on-content-click="false"
            offset-y
            nudge-bottom="8px"
            nudge-left="20px"
            transition="slide-y-reverse-transition"
          >
            <template v-slot:activator="menu">
              <v-tooltip bottom>
                <template v-slot:activator="tooltip">
                  <v-icon
                    size="26"
                    class="me-3"
                    v-bind="{ ...menu.attrs, ...tooltip.attrs }"
                    v-on="{ ...menu.on, ...tooltip.on }"
                    @click.stop
                  >
                    {{ icons.mdiArchive }}
                  </v-icon>
                </template>
                <span>Pengguna dihapus / diarsipkan</span>
              </v-tooltip>
            </template>
            <ArchivedUser
              :is-archive-open="isArchiveOpen"
              @update="fetchWorkspaceMember()"
            />
          </v-menu> -->
          <v-icon
            size="26"
            @click="fetchWorkspaceMember()"
          >
            {{ icons.mdiRefresh }}
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        class="mx-2 mb-2"
      >
        <!-- <v-col
          cols="12"
          lg="3"
          md="4"
        >
          <v-select
            v-model="state.userFilter.value.sort"
            placeholder="Sortir Berdasarkan"
            :items="sortOptions"
            outlined
            dense
            hide-details
            item-text="label"
            item-value="id"
            return-object
          >
            <template #item="{ item }">
              <div class="d-flex w-full py-2">
                <v-icon
                  size="18"
                  class="mr-2"
                >
                  {{ item.type === 'asc' ? icons.mdiSortAscending : icons.mdiSortDescending }}
                </v-icon>
                <span>{{ item.label }}</span>
              </div>
            </template>
            <template #selection="{ item }">
              <div class="d-flex w-full py-2">
                <v-icon
                  size="18"
                  class="mr-2"
                >
                  {{ item.type === 'asc' ? icons.mdiSortAscending : icons.mdiSortDescending }}
                </v-icon>
                <span>{{ item.label }}</span>
              </div>
            </template>
          </v-select>
        </v-col> -->
        <v-col
          cols="12"
          lg="2"
          md="3"
        >
          <v-badge
            bordered
            overlap
            class="w-100 cursor-pointer"
            :value="filterCount > 0"
            @click.native="clearFilter()"
          >
            <template #badge>
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </template>
            <v-btn
              :loading="loading"
              outlined
              block
              large
              :text="!filterCount > 0"
              :color="filterCount > 0? 'primary' : null"
              @click.stop="showFilterDialog = true"
            >
              Filter {{ filterCount > 0 ? `(${filterCount})` : '' }}
            </v-btn>
          </v-badge>
        </v-col>
        <v-col
          cols="12"
          md="auto"
          class="ms-0 ms-md-auto d-flex justify-space-between"
        >
          <!-- <v-btn-toggle
            v-model="viewTab"
            mandatory
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiTable }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Tabel</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiCardText }}</v-icon>
                </v-btn>
              </template>
              <span>Tampilan Kartu</span>
            </v-tooltip>
          </v-btn-toggle> -->
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ms-auto ms-md-4"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$refs.emailInvite.show()"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>Tambah Pengguna</span>
          </v-tooltip> -->

          <v-tooltip
            v-if="$can('create', 'User')"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <split-button
                class="ms-auto ms-md-4"
                color="primary"
                @click="$refs.userForm.show()"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
                <template #list>
                  <v-list-item @click="$refs.importUser.show()">
                    Import Data Pengguna
                  </v-list-item>
                </template>
              </split-button>
            </template>
            <span>Tambah Pengguna</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-tabs-items
        v-model="viewTab"
      >
        <!-- View Table -->
        <v-tab-item class="grid-container">
          <ejs-grid
            v-if="members"
            id="memberGrid"
            ref="memberGrid"
            :columns="tableColumns"
            :toolbar="toolbarOptions"
            :data-source="members"
            :edit-settings="editSettings"
            :page-settings="pageSettings"
            :toolbar-click="toolbarClick"
            :allow-paging="true"
            :allow-sorting="true"
            :allow-grouping="true"
            :allow-resizing="true"
            :allow-reordering="true"
            :allow-pdf-export="true"
            :allow-excel-export="true"
            :show-column-chooser="true"
            :data-bound="dataBound"
            :data-state-change="dataStateChange"
            :action-begin="handleActionBegin"
            :pdf-query-cell-info="pdfQueryCellInfo"
            :context-menu-items="contextMenuItems"
            :group-settings="groupSettings"
          />
        </v-tab-item>

        <!-- View Card -->
        <v-tab-item class="mx-4">
          <v-data-iterator
            :items="members"
            :items-per-page="10"
            item-key="id"
            show-select
            disable-sort
            :footer-props="{
              'items-per-page-options': [10, 20, 30],
              'items-per-page-text': 'Jumlah data',
            }"
            :server-items-length="userCount"
            mobile-breakpoint="0"
            :loading="loading"
            :options.sync="state.userFilter.value.pagination"
          >
            <template v-slot:default="props">
              <v-row class="match-height">
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="6"
                  md="4"
                  xl="3"
                >
                  <v-hover #default="{ hover }">
                    <v-card
                      outlined
                      class="d-flex flex-column flex-grow-1 pa-4"
                      @click="$router.push({ name: 'user-detail', params: { id: item.id } })"
                    >
                      <div class="d-flex mb-1 align-start">
                        <v-avatar
                          size="44"
                          class="v-avatar-light-bg mr-2"
                        >
                          <v-img
                            v-if="item.photo"
                            :src="item.photo"
                          />
                          <span v-else>{{ avatarText(`${item.first_name} ${item.last_name}`) }}</span>
                        </v-avatar>
                        <div>
                          <span class="text-subtitle-2 font-weight-bold d-block primary--text">{{ item.first_name }} {{ item.last_name }}</span>
                          <span class="text-caption text--disabled">{{ item.email }}</span>
                        </div>
                        <v-menu
                          bottom
                          left
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-slide-x-reverse-transition mode="out-in">
                              <v-icon
                                v-show="hover"
                                size="20px"
                                v-bind="attrs"
                                class="ml-auto mt-2"
                                v-on="on"
                              >
                                {{ icons.mdiDotsVertical }}
                              </v-icon>
                            </v-slide-x-reverse-transition>
                          </template>
                          <v-list>
                            <v-list-item>
                              <router-link :to="{ name: 'user-detail', params: { id: item.id } }">
                                Lihat Detail
                              </router-link>
                            </v-list-item>
                            <!-- <v-list-item
                              @click="$refs.userForm.update(item)"
                            >
                              Ubah Data
                            </v-list-item> -->
                            <v-list-item
                              class="error--text"
                              @click="removeUser(item.id)"
                            >
                              Hapus Pengguna
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      <span class="text-subtitle-2 font-medium">Nomor HP : {{ item.phone }}</span>
                      <div class="d-flex flex-wrap mt-2">
                        <v-chip
                          class="mr-1 mb-1"
                          color="primary"
                          small
                        >
                          {{ item.role.name }}
                        </v-chip>
                        <v-chip
                          class="mb-1"
                          :color="userStatusColor(item.user_status.id)"
                          small
                        >
                          {{ item.user_status.name }}
                        </v-chip>
                      </div>
                      <user-target-card class="mt-2" />
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template
              v-slot:footer.page-text="page"
            >
              Melihat {{ page.pageStart }} - {{ page.pageStop }} dari total {{ page.itemsLength }} data
            </template>
          </v-data-iterator>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-dialog
      v-model="showFilterDialog"
      max-width="600px"
    >
      <v-card
        class="rounded-b-0"
      >
        <v-card-title>Filter Pengguna</v-card-title>
        <div class="pa-4">
          <v-text-field
            v-model="search"
            label="Cari pengguna"
            placeholder="Cari berdasarkan nama"
            outlined
            dense
          />
          <v-select
            v-model="state.userFilter.value.role"
            :items="state.roles.value"
            label="Role"
            outlined
            multiple
            item-text="name"
            item-value="id"
            dense
            chips
            small-chips
          />
          <v-select
            v-model="state.userFilter.value.userStatus"
            :items="userStatus"
            label="Status"
            outlined
            multiple
            item-text="name"
            item-value="id"
            dense
            chips
            small-chips
          />
          <v-select
            v-model="state.userFilter.value.availability"
            :items="[
              {
                name: 'Tersedia',
                value: true,
              },
              {
                name: 'Tidak Tersedia',
                value: false,
              },
            ]"
            label="Ketersediaan"
            outlined
            item-text="name"
            item-value="value"
            dense
            chips
            small-chips
          />
          <v-btn
            block
            color="primary"
            class="mt-4"
            @click="filterMember"
          >
            Filter
          </v-btn>
          <v-btn
            block
            color="primary"
            class="mt-4"
            outlined
            @click="clearFilter"
          >
            Reset
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <user-form
      ref="userForm"
      @success="dataStateChange({ skip: pagination.value.page, take: pagination.value.itemsPerPage })"
    />
    <email-invite
      ref="emailInvite"
    />

    <import-user
      ref="importUser"
      @success="dataStateChange({ skip: pagination.value.page, take: pagination.value.itemsPerPage })"
    />

    <user-filter-builder
      ref="UserFilterBuilder"
      @close="dataStateChange({ skip: 0, take: pagination.value.itemsPerPage })"
    />
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-expressions */
import { getVuetify } from '@/@core/utils'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import {
  mdiMagnify, mdiClose, mdiPlus, mdiDotsVertical, mdiTable, mdiCardText,
  mdiSortAscending, mdiSortDescending,
} from '@mdi/js'
import { createFieldMapper } from 'vuex-use-fields'
import { useDebounceFn, useStorage } from '@vueuse/core'
import { formatDistance } from 'date-fns'
import SplitButton from '@/components/inputs/SplitButton.vue'
import { id } from 'date-fns/locale'
import { avatarText } from '@core/utils/filter'
// import Vue from 'vue'
// import UserCard from './UserCard.vue'
import useCustomAttributes from '@/composables/useCustomAttributes'
import useSavedView from '@/composables/useSavedView'
import { apolloClient } from '@/vue-apollo'
import { userStatuses, workspaceMembers } from '@/graphql/queries'
import {
  Page,
  Sort,
  Group,
  Freeze,
  Resize,
  Reorder,
  PdfExport,
  ExcelExport,
  Toolbar,
  Edit,
  ColumnChooser,
  LazyLoadGroup,
  ContextMenu,
} from '@syncfusion/ej2-vue-grids'
import store from '@/store'
// import router from '@/router'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import useUser from '@/composables/useUser'
import UserFilterBuilder from './UserFilterBuilder.vue'
// import ArchivedUser from './ArchivedUser.vue'
import UserForm from './UserForm.vue'
import ImportUser from './ImportUser.vue'
// import UserSavedView from './UserSavedView.vue'
// import TypeColumn from './columns/TypeColumn.vue'
import NameColumn from './columns/NameColumn.vue'
import CreatedColumn from './columns/CreatedColumn.vue'
import AvailabilityColumn from './columns/AvailabilityColumn.vue'
import ActionColumn from './columns/ActionColumn.vue'
// import SelectMultipleColumn from './columns/SelectMultipleColumn.vue'
// import UserTargetCard from './UserTargetCard.vue'
import EmailInvite from '@/views/workspace/EmailInvite.vue'
import errorHandling from '@/utils/errorHandling'
// import { removeFromWorkspace } from '@/graphql/mutations'

const {
  customAttributesArray,
  fetchCustomAttributes,
} = useCustomAttributes({ objectTypeId: 4 })
const {
  listSavedViews,
  listSavedDefaultViews,
  fetchSavedViews,
  addNewView,
  editView,
} = useSavedView({ objectTypeId: 4 })
const useFieldUser = createFieldMapper({ getter: 'user/getField', setter: 'user/setField' })

export default {
  name: 'User',
  components: {
    InputIconToggle,
    UserForm,
    // UserTargetCard,
    // UserCard,
    EmailInvite,
    ImportUser,
    // UserSavedView,
    // ArchivedUser,
    UserFilterBuilder,
    SplitButton,
  },
  provide: {
    grid: [
      Page,
      Sort,
      Group,
      Freeze,
      Resize,
      Reorder,
      PdfExport,
      Toolbar,
      ExcelExport,
      Edit,
      ColumnChooser,
      LazyLoadGroup,
      ContextMenu,
    ],
  },
  setup() {
    const $vuetify = getVuetify()
    const { userStatusColor, delUser } = useUser()
    const state = {
      ...useFieldUser([
        'users',
        'userCount',
        'userFilter',
        'headerSort',
        'sort',
        'search_field',
        'roles',
      ]),
    }
    // const typeColumn = () => ({ template: TypeColumn })
    const nameColumn = () => ({ template: NameColumn })
    const createdColumn = () => ({ template: CreatedColumn })
    const availabilityColumn = () => ({ template: AvailabilityColumn })

    const actionColumn = () => ({ template: ActionColumn })
    // const selectMultipleColumn = () => ({
    //   template: {
    //     extends: SelectMultipleColumn,
    //     propsData: { customAttributes: customAttributesArray.value },
    //   },
    // })

    const formatDate = date => formatDistance(new Date(Date.parse(date)), new Date(), { locale: id, addSuffix: true })
    // eslint-disable-next-line prefer-template
    // const statusAccess = (field, data) => { '<div class="d-flex flex-column align-end"><v-chip small :color="userStatusColor(data.user_status.id)">' + data.user_status.name + '</v-chip><span v-if="data.last_seen_at" class="text-caption text--disabled mt-1">Terakhir terlihat' + formatDate(data.last_seen_at) + '</span></div>' }
    // eslint-disable-next-line prefer-template
    const statusAccess = (field, data) => '<div class="d-flex flex-column align-end"><ejs-chiplist small :color="userStatusColor(data.user_status.id)">' + data.user_status.name + '</ejs-chiplist><span v-if="data.last_seen_at" class="text-caption text--disabled mt-1">Terakhir terlihat ' + formatDate(data.last_seen_at) + '</span></div>'
    // const statusAccess = (field, data) => console.log('data', data)

    // const singleAccess = (field, data) => {
    //   const customAttributeId = Number(field.split('.', 2)[1])

    //   if (data.custom_attributes_values === null || data.custom_attributes_values[customAttributeId] === undefined) {
    //     return ''
    //   }

    //   const customAttribute = customAttributesArray.value.find(el => el.custom_attributes.id === customAttributeId).custom_attribute

    //   return (customAttribute.options.find(el => el.id === Number(data.custom_attributes_values[customAttributeId].name)))
    // }
    // const multipleAccess = (field, data) => {
    //   const customAttributeId = Number(field.split('.', 2)[1])

    //   if (data.custom_attribute_values === null || data.custom_attributes_values[customAttributeId] === undefined) {
    //     return ''
    //   }

    //   const customAttribute = customAttributesArray.value.find(el => el.custom_attributes.id === customAttributeId).custom_attribute

    //   return JSON.parse(data.custom_attributes_values[customAttributeId]).map(el => customAttribute.options.find(opt => opt.id === el)).name
    // }

    const tableColumns = ref([
      {
        headerText: 'Nama',
        field: 'name',
        class: 'w-vdt-220',
        template: nameColumn,
      },
      {
        headerText: 'Role',
        field: 'role.name',
        width: '180px',
        allowSorting: false,
      },
      {
        headerText: 'Nomor HP',
        field: 'phone',
        width: '180px',
      },
      {
        headerText: 'Ketersediaan',
        field: 'availability',
        width: '150px',
        template: availabilityColumn,
      },
      {
        headerText: 'Dibuat pada',
        field: 'created_at',
        width: '280px',
        template: createdColumn,
      },
      {
        headerText: 'Status',
        field: 'user_status.name',
        align: 'end',
        width: '250px',
        valueAccessor: statusAccess,
        // template: typeColumn,
        disableHtmlEncode: false,
        allowSorting: false,
      },
      {
        headerText: '',
        field: 'action',
        width: '60px',
        template: actionColumn,
        allowSorting: false,
      },
    ])

    const sortOptions = ref([
      {
        id: 1,
        label: 'Nama Pengguna',
        field: 'first_name',
        type: 'asc',
      },
      {
        id: 2,
        label: 'Nama Pengguna',
        field: 'first_name',
        type: 'desc',
      },
      {
        id: 3,
        label: 'Tanggal Ditambahkan',
        field: 'created_at',
        type: 'asc',
      },
      {
        id: 4,
        label: 'Tanggal Ditambahkan',
        field: 'created_at',
        type: 'desc',
      },
      {
        id: 5,
        label: 'Terakhir Terlihat',
        field: 'last_seen_at',
        type: 'asc',
      },
      {
        id: 6,
        label: 'Terakhir Terlihat',
        field: 'last_seen_at',
        type: 'desc',
      },
    ])
    // const search = computed(() => state.userFilter.value.search)
    const search = ref('')
    const sort = computed(() => state.userFilter.value.sort)
    const showFilterDialog = ref(false)
    const filterCount = computed(() => state.userFilter.value.filterCount)
    // const filterCount = computed(() => store.getters['user/filterCount'])
    const userCount = ref(0)
    const selectedRows = ref([])
    const loading = ref(false)
    const userForm = ref(null)
    const userSavedView = ref(null)
    const members = ref([])
    const menu = ref(false)
    const memberGrid = ref(null)
    const headers = ref([])
    // const pagination = computed(() => state.userFilter.value.pagination)
    const pagination = ref({})
    const viewTab = useStorage('viewTabUser', 0)
    const userStatus = ref([])
    apolloClient.query({
      query: userStatuses,
    }).then(result => {
      userStatus.value = result.data.userStatuses
    })

    const contextMenuItems = ref([
      'AutoFit', 'AutoFitAll',
      'PdfExport', 'ExcelExport',
      'SortAscending', 'SortDescending',
      'Copy', 'Edit', 'Delete', 'Save', 'Cancel',
      'FirstPage', 'PrevPage', 'LastPage', 'NextPage',
    ])

    const toolbarOptions = ref([
      'PdfExport',
      'ExcelExport',
      'Edit',
      'ColumnChooser',
      {
        text: 'Saved Views',
        tooltipText: 'Tampilan Tabel',
        prefixIcon: 'e-expand',
        id: 'saved_views',
        align: 'right',
      },
    ])

    const fetchWorkspaceMember = () => {
      console.log('sort', sort)
      loading.value = true

      return new Promise((resolve, reject) => {
        apolloClient.query({
          query: workspaceMembers,
          fetchPolicy: 'no-cache',
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            filter: {
              role_id: state.userFilter.value.role.length ? state.userFilter.value.role : null,
              sort: sort.value.field,
              // sort: state.headerSort.value.length ? state.headerSort.value.map(item => ({ sort: item.sort, order: item.order })) : { sort: state.sort.value.sort, order: state.sort.value.order },
              order: sort.value.type.toUpperCase(),
              availability: state.userFilter.value.availability,
              user_status: state.userFilter.value.userStatus.length ? state.userFilter.value.userStatus : null,
            },
            pagination: {
              limit: pagination.value.itemsPerPage,
              offset: pagination.value.page,
            },
            search: search.value,
          },
        }).then(({ data }) => {
          userCount.value = data.workspaceMembers.users_count
          resolve({
            result: data.workspaceMembers.members.map(value => ({ ...value.user, workspace: value.workspace, role: value.role })),
            count: data.workspaceMembers.users_count,
          })
          console.log('arg3', members)
          loading.value = false
        }).catch(err => {
          loading.value = false
          console.log(err)
          reject(err)
          errorHandling(err)
        })
      })
    }

    // const fetchWorkspaceMember = () => {
    //   // const filter = state.userFilter.value
    //   loading.value = true
    //   // console.log('filter', filter)

    //   return new Promise((resolve, reject) => {
    //     console.log('members')
    //     apolloClient.query({
    //       query: workspaceMembers,
    //       variables: {
    //         pagination: {
    //           limit: pagination.value.itemsPerPage,
    //           offset: pagination.value.page,
    //         },
    //         filter: {
    //           role_id: state.userFilter.value.role.length ? state.userFilter.value.role : null,
    //           sort: sort.value.field,
    //           order: sort.value.type.toUpperCase(),
    //           availability: state.userFilter.value.availability,
    //           user_status: state.userFilter.value.userStatus.length ? state.userFilter.value.userStatus : null,
    //         },
    //         search: search.value,
    //         workspace_id: store.getters.getCurrentWorkspaceId,
    //       },
    //       fetchPolicy: 'no-cache',
    //     }).then(async result => {
    //       console.log(result, 'membersss')
    //       console.log(state, 'members')
    //       store.commit('user/setField', { location: 'users', value: result.data.workspaceMembers.members.map(value => ({ ...value.user, workspace: value.workspace, role: value.role })) })
    //       store.commit('user/setField', { location: 'usersCount', value: result.data.workspaceMembers.users_count })
    //       await fetchCustomAttributes()
    //       resolve({
    //         result: result.data.workspaceMembers.members.map(value => ({ ...value.user, workspace: value.workspace, role: value.role })),
    //         count: result.data.workspaceMembers.users_count,
    //       })
    //       loading.value = false
    //       console.log(result, 'membersss2')
    //     }).catch(err => {
    //       loading.value = false
    //       router.push('/error')
    //       reject(err)
    //       errorHandling(err, 'Daftar Pengguna')
    //     })
    //   })
    // }

    const clearFilter = () => {
      state.userFilter.value = {
        pagination: {
          groupBy: [],
          groupDesc: [],
          itemsPerPage: 10,
          multiSort: false,
          mustSort: false,
          page: 1,
        },
        filterCount: 0,
        search: null,
        sort: {
          id: 6,
          label: 'Terakhir Terlihat',
          field: 'last_seen_at',
          type: 'desc',
        },
        role: [],
        userStatus: [],
        availability: null,
      }
      showFilterDialog.value = false
    }

    const pageSettings = ref({
      pageSizes: [10, 20, 30],
      pageSize: 10,
      pageCount: 4,
      currentPage: 1,
    })

    const editSettings = ref({
      allowEditing: true,
      allowDeleting: true,
      mode: 'Dialog',
    })

    const groupSettings = ref({
      enableLazyLoading: false,
    })

    const toolbarClick = args => {
      if (args.item.id === 'memberGrid_pdfexport') {
        memberGrid.value.pdfExport()
      } else if (args.item.id === 'memberGrid_excelexport') {
        memberGrid.value.excelExport()
      } else if (args.item.id === 'saved_views') {
        userSavedView.value.show()
      }
    }

    const dataStateChange = args => {
      console.log('arg', args)
      console.log('arg2', sort)
      pagination.value = {
        page: args.skip,
        itemsPerPage: args.take,
      }
      if (args.sorted) {
        sort.value.field = args.sorted[0].name
        sort.value.type = args.sorted[0].direction === 'ascending' ? 'ASC' : 'DESC'
      } else if (!args.sorted && args.action?.requestType === 'sorting') {
        state.sort.value = {
          field: null,
          type: null,
        }
      }
      fetchWorkspaceMember().then(data => {
        console.log('datastate', data)
        members.value = data
      })
    }

    // const removeUser = userData => {
    //   console.log(userData)
    //   Vue.$dialog({
    //     title: 'Keluarkan Pengguna?',
    //     body: `Yakin ingin mengeluarkan ${userData.first_name} ${userData.last_name}?`,
    //   }).then(result => {
    //     if (result) {
    //       apolloClient.mutate({
    //         mutation: removeFromWorkspace,
    //         variables: {
    //           workspace_id: store.getters.getCurrentWorkspaceId,
    //           user_id: userData.id,
    //         },
    //       }).then(() => {
    //         Vue.notify({
    //           title: 'Sukses!',
    //           text: 'Berhasil mengeluarkan pengguna!',
    //         })
    //       }).catch(err => {
    //         console.log(err)
    //         errorHandling(err)
    //       })
    //     }
    //   })
    // }

    const searchUser = useDebounceFn(() => {
      dataStateChange({ skip: 0, take: pagination.value.itemsPerPage })
    }, 1000)

    // eslint-disable-next-line no-shadow
    const removeUser = id => {
      delUser(id).then(() => {
        dataStateChange({ skip: pagination.value.page, take: pagination.value.itemsPerPage })
      })
    }

    const pdfQueryCellInfo = args => {
      if (args.column.field === 'name') {
        // eslint-disable-next-line no-param-reassign
        args.style = {
          backgroundColor: '#99ffcc',
        }
      }
    }

    const handleActionBegin = args => {
      // console.log(args, 'args')
      // console.log(memberGrid.value)
      /* eslint-disable no-param-reassign */
      switch (args.requestType) {
        case 'beginEdit':
          args.cancel = true
          userForm.value.update(args.rowData)
          break
        case 'delete':
          args.cancel = true
          removeUser(args.data[0].id)
          break
        case 'grouping':
          args.cancel = true
          memberGrid.value.ej2Instances.getColumnByField('name').isFrozen = false
          memberGrid.value.ej2Instances.refreshColumns()
          memberGrid.value.groupColumn(args.columnName)
          memberGrid.value.sortColumn(args.columnName, 'Ascending')
          break
        case 'ungrouping':
          memberGrid.value.ej2Instances.getColumnByField('name').isFrozen = true
          memberGrid.value.ej2Instances.refreshColumns()
          memberGrid.value.clearSorting()
          break
        default:
          break
      }
      /* eslint-enable no-param-reassign */
    }

    const initLayout = () => {
      // const gridColumns = listSavedViews.value.find(el => el.is_selected).fields
      // tableColumns.value = gridColumns.map(el => ({
      //   ...tableColumns.value[el.index],
      //   width: el.width,
      //   visible: el.visible,
      //   index: el.index,
      // }))
    }

    const flag = ref(true)
    const dataBound = () => {
      flag.value = false

      // customAttributesArray.value.forEach(el => {
      //   if ([1, 2].includes(el.custom_attributes.data_type.id)) {
      //     tableColumns.value.push({
      //       field: `custom_attributes_values.${el.custom_attributes.id}`,
      //       headerText: el.custom_attributes.name,
      //       width: '200',
      //       textAlign: 'Left',
      //       dataTypeId: el.custom_attributes.data_type.id,
      //     })
      //   } else if (el.custom_attributes.data_type.id === 3) {
      //     tableColumns.value.push({
      //       field: `custom_attributes_values.${el.custom_attributes.id}`,
      //       headerText: el.custom_attributes.name,
      //       width: '200',
      //       textAlign: 'Left',
      //       type: 'date',
      //       format: { type: 'date', format: 'dd MMMM yy' },
      //       editType: 'datepickeredit',
      //       edit: "{ params: { format: 'dd.MM.yy' }}",
      //       dataTypeId: el.custom_attributes.data_type.id,
      //     })
      //   } else if (el.custom_attributes.data_type.id === 4) {
      //     tableColumns.value.push({
      //       field: `custom_attributes_values.${el.custom_attributes.id}`,
      //       headerText: el.custom_attributes.name,
      //       width: '200',
      //       textAlign: 'Left',
      //       valueAccessor: singleAccess,
      //       dataTypeId: el.custom_attributes.data_type.id,
      //     })
      //   } else if (el.custom_attributes.data_type.id === 5) {
      //     tableColumns.value.push({
      //       field: `custom_attributes_values.${el.custom_attributes.id}`,
      //       headerText: el.custom_attributes.name,
      //       width: '200',
      //       textAlign: 'Left',
      //       valueAccessor: multipleAccess,
      //       allowSorting: false,
      //       template: selectMultipleColumn,
      //       dataTypeId: el.custom_attributes.data_type.id,
      //     })
      //   }
      // })

      initLayout()
    }

    const saveLayout = async (viewName, viewId) => {
      const currentProperties = JSON.parse(memberGrid.value.ej2Instances.getPersistData()).columns
      const currentColumns = [...memberGrid.value.ej2Instances.getColumns()]
      const savedColumns = currentProperties.map(el => {
        const {
          headerText, visible, index, width, dataTypeId,
        } = currentColumns.find(colColumnsState => colColumnsState.field === el.field)

        return {
          headerText,
          visible,
          index,
          width,
          dataTypeId,
        }
      })

      if (viewId) {
        await editView(savedColumns, viewName, viewId)
      } else {
        await addNewView(savedColumns, viewName)
      }
      await fetchSavedViews()
    }

    // const restoreLayout = savedColumns => {
    //   const currentProperties = JSON.parse(memberGrid.value.ej2Instances.getPersistData()).columns
    //   const staticProperties = {
    //     0: { template: nameColumn },
    //     4: { template: statusAccess },
    //   }
    //   const customPropeties = {
    //     4: { template: singleAccess },
    //     5: { template: selectMultipleColumn, valueAccessor: multipleAccess },
    //   }

    //   const newColumns = savedColumns.map(el => {
    //     const idx = currentProperties.findIndex(col => col.index === el.index)

    //     return {
    //       ...currentProperties[idx],
    //       ...el,
    //       ...(staticProperties[el.index] || {}),
    //       ...(customProperties[el.dataTypeId] || {})
    //     }
    //   })

    //   if (newColumns.length !== currentProperties.length) {

    //   }

    //   memberGrid.value.ej2Instances.setPropeties({ columns: newColumns })
    // }

    const filterMember = () => {
      const filter = state.userFilter.value
      console.log(filter)
      filter.filterCount = 0
      for (const key in filter) {
        if (key === 'availability') {
          filter[key] ? filter.filterCount += 1 : null
        }
        if (key === 'userStatus') {
          filter[key].length ? filter.filterCount += 1 : null
        }
        if (key === 'role') {
          filter[key].length ? filter.filterCount += 1 : null
        }
        if (key === 'search') {
          filter[key] ? filter.filterCount += 1 : null
        }
      }
      showFilterDialog.value = false
      fetchWorkspaceMember()
    }

    const setTheme = () => {
      if ($vuetify.theme.dark) {
        document.getElementById('theme').href = '/syncfusion-theme/grid/ej2-grid-dark.css'
      } else {
        document.getElementById('theme').href = '/syncfusion-theme/grid/ej2-grid-light.css'
      }
    }

    const searchProduct = useDebounceFn(() => {
      fetchWorkspaceMember()
    }, 1000)
    watch(sort, () => {
      fetchWorkspaceMember()
    })
    watch($vuetify, () => {
      setTheme()
    })
    onMounted(() => {
      setTheme()
      // fetchCustomAttributes()
      dataStateChange({ skip: 0, take: 10 })
    })

    return {
      userForm,
      contextMenuItems,
      toolbarOptions,
      toolbarClick,
      handleActionBegin,
      memberGrid,
      pageSettings,
      editSettings,
      dataStateChange,
      statusAccess,
      pdfQueryCellInfo,
      dataBound,
      customAttributesArray,
      saveLayout,
      // restoreLayout,
      initLayout,
      groupSettings,
      userSavedView,
      searchUser,
      menu,
      listSavedViews,
      listSavedDefaultViews,
      fetchSavedViews,
      addNewView,
      editView,
      fetchCustomAttributes,
      headers,

      userCount,
      members,
      pagination,
      search,
      filterCount,
      loading,
      tableColumns,
      showFilterDialog,
      selectedRows,
      clearFilter,
      viewTab,
      userStatusColor,
      avatarText,
      sortOptions,
      state,
      removeUser,
      formatDate,
      userStatus,
      searchProduct,
      fetchWorkspaceMember,
      filterMember,
      icons: {
        mdiMagnify,
        mdiClose,
        mdiPlus,
        mdiDotsVertical,
        mdiTable,
        mdiCardText,
        mdiSortAscending,
        mdiSortDescending,
      },
    }
  },
}
</script>

<style>
    .material-dark .card-control-section.basic_card_layout .e-card .e-card-header {
        padding: 10px;
    }

    .card-control-section.basic_card_layout #weather_card.e-card .e-card-header-caption .e-card-header-title,
    .card-control-section.basic_card_layout #weather_card.e-card .e-card-header-caption .e-card-sub-title {
        color: white;
    }

    .highcontrast .card-control-section.basic_card_layout #weather_card.e-card .e-card-header.weather_report .e-card-header-image {
        border: none;
    }

    .card-control-section.basic_card_layout #weather_card.e-card .weather_report .e-card-header-caption {
        text-align: right;
    }

    .card-control-section.basic_card_layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
        width: 100%;
        padding: 10px;
    }

    .card-control-section.basic_card_layout .card-layout {
        margin: auto;
        max-width: 400px;
    }

    @media (min-width: 870px) {
        .card-control-section.basic_card_layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
            width: 50%;
        }

        .card-control-section.basic_card_layout .card-layout {
            max-width: 870px;
        }
    }
    .grid-container:after,
.grid-container::after {
  clear: both;
  content: "";
  display: table;
}
</style>
