<template>
  <v-switch
    false-value="false"
    true-value="true"
    :value="data.availability"
    disabled
  />
</template>

<script>
import { reactive } from '@vue/composition-api'

export default {
  setup() {
    const data = reactive({})

    return {
      data,
    }
  },
}
</script>
