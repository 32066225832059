<template>
  <div class="d-flex flex-column align-start">
    <span
      class=""
    >
      {{ dateFormat(data.created_at) }}
    </span>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api'
import dateFormat from '@/utils/dateFormat'

export default {
  setup() {
    const data = reactive({})

    return {
      data,
      dateFormat,
    }
  },
}
</script>
