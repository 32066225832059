/**
 * Fungsi untuk memformat nomor telepon menjadi nomor yang valid
 * @method
 * @param {string} phone
 * @returns {string}
 */
export default function phoneParser(phone) {
  if (phone.startsWith('08')) {
    return `628${phone.substring(2)}`
  }

  if (phone.startsWith('+62')) {
    return `62${phone.substring(3)}`
  }

  return phone
}
