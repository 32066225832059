<template>
  <v-autocomplete
    v-model="inputData"
    :items="items"
    chips
    :label="label"
    deletable-chips
    small-chips
    item-text="name"
    outlined
    dense
    hide-no-data
    :multiple="multiple"
    placeholder="Cari kota..."
    :loading="loading"
    return-object
    :search-input.sync="search"
    hide-details="auto"
    :rules="rules"
    @update:search-input="searchCity"
    @input="search = ''"
  />
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import { cities } from '@/graphql/queries'

export default {
  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })
    const loading = ref(false)
    const items = ref([])
    const search = ref('')

    const searchCity = useDebounceFn(() => {
      if (search.value) {
        loading.value = true
        apolloClient.query({
          query: cities,
          variables: {
            search: search.value,
          },
        }).then(result => {
          loading.value = false

          if (inputData.value) {
            items.value = Array.isArray(inputData.value)
              ? [...inputData.value, ...result.data.cities]
              : [inputData.value, ...result.data.cities]
          } else {
            items.value = result.data.cities
          }
        }).catch(() => {
          loading.value = false
        })
      }
    }, 1000)

    onMounted(() => {
      if (props.value && props.value.name) {
        items.value = [props.value]
      }
    })

    return {
      inputData,
      loading,
      items,
      search,
      searchCity,
    }
  },
}
</script>

<style>

</style>
