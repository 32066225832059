<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    scrollable
  >
    <v-card>
      <div class="pa-4 d-flex justify-space-between">
        <span class="text-subtitle-1 font-medium primary--text">Import Data Pengguna</span>
        <v-icon
          @click="isOpen = false"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <v-card-text class="pt-0">
        <v-tabs-items v-model="currentTab">
          <v-tab-item>
            <div v-if="currentTab === 0">
              <v-row class="match-height mt-2">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-card
                    outlined
                    class="pa-3"
                  >
                    <div class="d-flex mb-2">
                      <v-chip small>
                        1
                      </v-chip>
                      <span class="text-headline font-medium ml-2">Download Template</span>
                    </div>
                    <span class="d-block text-subtitle-2 font-weight-regular">
                      Silahkan download salah satu template di bawah ini untuk menambahkan banyak pengguna sekaligus. Pastikan data pengguna sudah sesuai format agar terhindar dari kesalahan input.
                    </span>
                    <div class="d-flex mt-3">
                      <v-card
                        outlined
                        class="py-2 px-3 d-flex mr-2"
                        href="https://oriens.node.zoomit.co.id/assets/import/user.xlsx"
                      >
                        <v-icon class="mr-2 primary--text">
                          {{ icons.mdiFile }}
                        </v-icon>
                        <span class="text-subtitle-1 primary--text">
                          .XLSX
                        </span>
                      </v-card>
                      <v-card
                        outlined
                        class="py-2 px-3 d-flex mr-2"
                        href="https://oriens.node.zoomit.co.id/assets/import/user%20CSV.csv"
                      >
                        <v-icon class="mr-2 primary--text">
                          {{ icons.mdiFile }}
                        </v-icon>
                        <span class="text-subtitle-1 primary--text">
                          .CSV
                        </span>
                      </v-card>
                    </div>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-card
                    outlined
                    class="pa-3"
                  >
                    <div class="d-flex mb-4">
                      <v-chip small>
                        2
                      </v-chip>
                      <span class="text-headline font-medium ml-2">Upload File Template</span>
                    </div>
                    <drop-file
                      ref="filePicker"
                      max-file="1"
                      max-size="5000"
                      subtitle="Upload file template yang sudah diisi."
                      disable-preview
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                      @fileAdded="isValid = true"
                      @fileDeleted="isValid = false"
                    />
                  </v-card>
                </v-col>
              </v-row>
              <div class="d-flex flex-column flex-md-row mt-4">
                <v-btn
                  class="ml-auto"
                  color="primary"
                  :loading="loadingUpload"
                  :disabled="!isValid"
                  @click="upload"
                >
                  Selanjutnya
                </v-btn>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item class="d-flex flex-column">
            <div v-if="currentTab === 1">
              <span class="text-subtitle-1">Pelanggan Duplikat</span>
              <v-alert
                border="left"
                color="warning"
                dense
                text
                type="info"
                class="mb-4 mt-2 text-subtitle-2 py-2"
              >
                <span class="text-caption">Ditemukan beberapa data duplikat, kamu bisa menghapus atau melanjutkan import data.</span>
              </v-alert>
              <v-row dense>
                <v-col
                  v-for="user in duplicateUser"
                  :key="user.existing_user_id"
                  cols="12"
                  md="4"
                >
                  <v-card
                    outlined
                    class="pa-3 d-flex align-center"
                  >
                    <div class="d-flex flex-column">
                      <span class="text-subtitle-2 font-weight-bold d-block">{{ user.name }}</span>
                      <span class="text-caption font-medium">{{ user.email }}</span>
                      <span class="text-caption font-medium">{{ user.phone_number }}</span>
                    </div>
                    <v-btn
                      class="ml-auto rounded"
                      outlined
                      text
                      icon
                      color="error"
                      @click="deleteUser(user.existing_user_id)"
                    >
                      <v-icon>
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
              <div class="d-flex flex-column flex-md-row mt-4 mb-2">
                <v-btn
                  color="primary"
                  text
                  outlined
                  @click="currentTab = 0"
                >
                  Ubah Template Import
                </v-btn>
                <v-btn
                  class="ml-auto"
                  color="primary"
                  :loading="loadingSubmit"
                  @click="submit"
                >
                  Import Pengguna
                </v-btn>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'
import { mdiClose, mdiFile } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { apolloClient } from '@/vue-apollo'
import { uploadCustomer } from '@/graphql/queries'
import { insertBatchCustomer } from '@/graphql/mutations'
import DropFile from '@/components/inputs/DropFile.vue'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

export default {
  components: {
    DropFile,
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const loadingUpload = ref(false)
    const loadingSubmit = ref(false)
    const isValid = ref(false)
    const filePicker = ref()
    const currentTab = ref(0)
    const customers = ref([])

    const duplicateCustomer = computed(() => customers.value.filter(el => el.existing_customer_id !== null))

    const show = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    const reset = () => {
      loadingSubmit.value = false
      currentTab.value = 0
      customers.value = []
      isOpen.value = false
    }

    const submit = () => {
      loadingSubmit.value = true
      apolloClient.mutate({
        mutation: insertBatchCustomer,
        variables: {
          data: customers.value.map(el => ({
            existing_customer_id: el.existing_customer_id,
            name: el.name,
            gender: el.gender,
            description: el.description,
            address: el.address,
            job_title: el.job_title,
            birth_date: el.birth_date,
            phone_number: el.phone_number,
            whatsapp_number: el.whatsapp_number,
            email: el.email,
          })),
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        emit('success', result)
        reset()
        Vue.notify({
          title: 'Berhasil!',
          text: 'Berhasil import data pelanggan',
        })
      }).catch(err => {
        errorHandling(err)
        loadingSubmit.value = false
      })
    }

    const upload = () => {
      const file = filePicker.value.getAcceptedFiles()

      if (file.length) {
        loadingUpload.value = true
        apolloClient.query({
          query: uploadCustomer,
          variables: {
            file: file[0].file,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
          fetchPolicy: 'no-cache',
        }).then(result => {
          loadingUpload.value = false

          if (result.data.uploadCustomer && result.data.uploadCustomer.length) {
            customers.value = result.data.uploadCustomer

            if (result.data.uploadCustomer.every(el => el.existing_customer_id === null)) {
              submit()
            } else {
              currentTab.value += 1
            }
          }
        }).catch(err => {
          loadingUpload.value = false
          emit('error', err)
          errorHandling(err, 'Tambah Pelanggan')
        })
      }
    }

    const deleteCustomer = id => {
      Vue.$dialog({
        title: 'Hapus pelanggan?',
        body: 'Konfirmasi jika anda ingin menghapus pelanggan.',
      }).then(confirm => {
        if (confirm) {
          customers.value = customers.value.filter(el => el.existing_customer_id !== id)
        }
      })
    }

    return {
      isOpen,
      isValid,
      show,
      close,
      upload,
      submit,
      loadingUpload,
      loadingSubmit,
      filePicker,
      currentTab,
      avatarText,
      duplicateCustomer,
      deleteCustomer,

      icons: {
        mdiClose,
        mdiFile,
      },
    }
  },
}
</script>

<style>

</style>
